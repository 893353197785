/* Container Offset */
.container-offset {
    padding-left: 76px !important;
}

/* Content Holder */
.content-holder {
    padding: 15px;
}

/* Top Bar */
.top-bar {
    height: 8vh;
    background-color: #980000;
    padding: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 5;
    position: relative;
}

.top-bar p {
    color: #FFF;
    font-size: 1.5rem;
    text-transform: uppercase;
    font-weight: 600;
    margin: 0;
}

.top-bar img {
    height: 6vh;
    width: auto;
}

/* Main Dashboard Screen */
.main-dashboard-screen {
    display: flex;
    justify-content: space-between;
}

/* Raffles Sold Number */
.raffles-sold-number {
    background-color: #980000 !important;
    display: flex;
    flex-wrap: wrap;
    color: #FFF;
}

.raffles-sold-content {
    align-items: center;
    margin: 0 !important;
}

.raffles-sold-number-icon {
    width: 64px;
    height: 64px;
    background-color: #640000;
    border-radius: 10px 0px 0px 10px;
}

.raffles-sold-number-icon svg {
    padding: 12px;
    width: 64px;
    height: 64px;
}

.raffles-sold-number-text {
    padding-left: 25px !important;
}

/* Raffles List */
.raffles-list {
    padding: 3%;
    border: solid 2px #98000020 !important;
    margin-top: 25px;
    color: #222;
    overflow-x: scroll;
}

.raffles-list h3 {
    color: #222;
}

/* Users List */
.users-list {
    padding: 3%;
    border: 1.7px solid #98000050 !important;
    margin-top: 25px;
    color: #222;
    box-shadow: none !important;
    overflow-x: scroll;
}

.users-filter {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

/* Raffle Filter */
.raffle-filter {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

.raffle-filter input {
    margin: 15px !important;
}

/* Form Card */
.form-card {
    box-shadow: none !important;
    filter: drop-shadow(0);
    border-radius: 15px;
    border: 1.7px solid #98000020 !important;
    padding: 5px;
    margin: 0 !important;
}

/* Scrollbar */
::-webkit-scrollbar {
    width: 5px;
    height: 5px;
}

::-webkit-scrollbar-track {
    background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
    background: #98000020;
}

::-webkit-scrollbar-thumb:hover {
    background: #980000;
}

/* Canvas Background */
.canvas-background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
}

/* Login Card */
.login-card {
    color: #FFF !important;
}

/* Login Button */
.login-btn {
    filter: drop-shadow(0) !important;
    box-shadow: none !important;
    transition: 0.3s !important;
}

.login-btn:hover {
    transform: scale(1.05);
}

/* Campaign Inputs */
.campaign-text {
    padding: 8px;
    border: 1px solid #ced4da;
    border-radius: 4px;
}

.campaign-date {
    padding: 8px;
    border: 1px solid #ced4da;
    border-radius: 4px;
}

/* Button Create */
.button-create {
    background-color: #980000;
    color: #FFF;
    font-size: 1.4rem;
    border-radius: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.4rem 3rem;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s;
}

.button-create:hover {
    background-color: #640000;
}

/* Focus Image Holder */
.focus-img-holder {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

/* Tabs */
.tabs {
    display: flex;
    justify-content: space-around;
    border-bottom: 2px solid #980000;
    margin-bottom: 15px;
}

.tab {
    padding: 10px 20px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.tab.active {
    background-color: #980000;
    color: #FFF;
}

.tab-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.form-row {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.form-group {
    flex-basis: 45%;
    margin-bottom: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem 5rem;
    margin: 1rem 2rem;
    border-bottom: 0.1rem solid #1e1e1e10;
}

.form-group label {
    color: #000;
    font-size: 1.1em;
}

.form-group-input {
    min-width: 200px !important;
}

.raffles-edit-list {
    display: flex;
    justify-content: center;
    padding: 2rem 0rem;
}

.raffles-edit-list select {
    border-radius: 0.5rem;
    font-size: 1.3em;
    padding: 0.2rem;
}

.pagination-wrapper {

}

.page-button {
    background-color: #FFF !important;
    border: none !important;
    transition: 300ms;
}

.page-button:hover {
    color: #FF0000;
    transform: scale(1.1);
}

.pagination {
    padding: 2rem;
    display: flex;
    justify-content: center;
}