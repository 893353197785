/* btnRifa.css */
.rifa-card {
    border-radius: 0.8rem;
    background-color: #FFF;
    text-align: left;
    max-width: 420px;
    overflow: hidden;
    width: 100%;
    min-height: 130px;
    filter: drop-shadow(0rem 0.4rem 0.3rem #0002);
    margin: 2rem 0rem;
}

.content-wrapper {
    height: 100%;
    min-height: 130px !important;
}

.rifa-card img {
    width: 100%;
    height: 100%;
}

.rifa-info {
    color: #000;
    height: 100%;
    display: flex;
    flex-direction: column; 
    justify-content: space-around;
}

.rifa-info p {
    font-size: 70%;
    color: #919191;
    margin: 0;
}

.rifa-info h2 {
    font-size: 1.2rem;
}

.button-price {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.rifa-info button {
    background-color: #980000;
    font-weight: bolder;
    font-size: 1.1rem;
    color: #FFF;
    padding: 0.2rem 0.8rem;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    display: inline-flex !important;    
}

.rifa-price {
    color: #000;
    font-weight: bolder;
    font-size: 1.4rem;
    padding: 0rem 0rem 0rem 0rem;
}

.img-wrapper {
    display: flex;
    justify-content: start;
    align-items: center;
    padding: 0 !important;
}

.card-container {
    display: flex;
    justify-content: center;
    align-items: center;
}