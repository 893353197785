.navbarContainer {
    background-color: #640000;
    width: 250px;
    min-height: 100vh;
    transition: width 0.1s;
    display: flex;
    justify-content: center;
}

.navbarCollapsed {
    width: 64px;
    display: flex;
}

.navbarIconsHolder{
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.navbarIconsHolder svg{
    color: #FFF;
    font-size: 42px;
    padding: 5px;
}

.navbarIcons {
    color: #FFF;
    display: flex;
    align-items: center;
    font-size: 1.4rem;
    padding: 5px;
}

.navbarIcons svg{
    font-size: 38px;
    color: #FFF;
}

.navbarButtonHolder {
    width: 0px;
    min-height: 100vh;
}

.navbarButton {
    color: #FFF;
    background-color: #640000;
    border-radius: 0px 15px 15px 0px;
    position: relative;
    top: 50%;
    transform: translate(-50%, -50%);
    height: 64px;
    width: 48px;
    padding: 0;
    margin: 0;
    border: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: -2;
    transition: 0.3s;
}

.navbarButton:hover {
    transform: translate(-15%, -50%);
    cursor: pointer;
}

.navbarButton svg {
    color: #FFF;
    height: 18px;
    width: 18px;
    transform: translate(50%, 0%);
}

.dropdownContainer {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
}

.dropdownContainer a {
    color: #FFF;
}

.dropdownContainer a:hover {
    color: #000;
}

.dropdown {
    display: flex;
    flex-direction: column;
    justify-content: end;
    align-items: start;
    margin-left: 45px;
}